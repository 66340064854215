// @ts-nocheck
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Register from "./components/Register";
import EventSelection from "./sections/EventSelection";

import CreatePermissionPage from "./sections/CreatePermission";
import ListPermissionPage from "./sections/ListPermissions";
import ListenPermissionPermissionPage from "./sections/ListenPermissions";
import UpdatePermissionPage from "./sections/UpdatePermission";

import EventWaiting from "./sections/eventWaiting";
import Lobby from "./sections/loby.tsx";
import ButtonAppBar from "./components/AppBar";
import MainEvent from "./sections/EventMain";
// import ControlPanel from './components/ControlPanel';
import { ControlPanel } from "./components/ControlPanel/index.tsx";
import ErrorPage from "./sections/error404";
import Login from "./components/Login";

// Test
import KeyboardShortcuts from "./components/keyboardShortcuts";
import AllowCameraAndMicrophone from "./components/devicePermission/allow_camera_and_microphone";
import CameraAndMicrophoneBlocked from "./components/devicePermission/camera_and_microphone_blocked";
import CameraAndMicrophoneNotUsed from "./components/devicePermission/camera_and_microphone_not_used";
import Helmet from "react-helmet";
import EventPage from "./admin/components/EventPage";
import Team from "./admin/components/Team";
import AdminPanel from "./admin/components";
import { Centrifuge } from "centrifuge";
import { setCentrifugoClient } from "./state/conference/conferenceSlice";
import { setPermissions } from "./state/permissions/permissionSlice";
import Analytics from "./admin/components/Analytics";
import RolePage from "./admin/components/RolePage";
import AccountPage from "./admin/components/account/AccountPage";
import FileManager from "./sections/FileManager";
import { setAddLoggedinUser } from "./state/usersLoggedin/usersLoggedinSlice";
import { AxiosLocal } from "./utilities/axiosUtils.ts";
import { client } from "./utilities/centrifugoUtils.ts";
import {
  subscribeCentrifugoChannel,
  CHANNEL_TYPES,
} from "./utilities/centrifugoUtils.ts";
import PermissionPage from "./admin/components/permissions/PermissionPage";
import Permission from "./admin/components/Permissions";

import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import MediaFileManager from "./admin/components/MediaFileManager";
import File from "./admin/components/file_manager/File";
import { EventGate } from "./sections/EventGate.tsx";
import { setuserProfile } from "./state/userProfile/userProfileSlice";
import { PublicTrack } from "./components/publicTrack/index.tsx";
import { getTeamSlugFromUrl } from "./utilities/common";
import ResetPassword from "./components/reset_password/ResetPassword";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const [Valid, setValid] = useState(true);
  // show AppBar state
  const [showAppBar, setShowAppBar] = useState(true);
  const navigate = useNavigate();
  // Function to handle incoming messages from other tabs
  function handleMessage(event) {
    if (event.data.type === "localStorageChange") {
      console.log("Received local storage change", event.data);
      // Update the local storage with the new value
      localStorage.setObject(event.data.key, event.data.value);
    }
  }

  // Add an event listener to listen for incoming messages
  window.addEventListener("message", handleMessage);

  const slugs = useParams();
  /*useEffect(() => {
    document.body.style.backgroundColor = "#032e57"
  }, [])*/
  let location = useLocation();
  // wrap checkGuest in useCallback
  const checkGuest = React.useCallback(
    (is_lobby, add_password, pathname) => {
      console.log("is_lobby", is_lobby);
      console.log("add_password", add_password);
      console.log("pathname", pathname);

      if (is_lobby) {
        localStorage.setObject("guestUserVisitingLoby", "true");
        localStorage.setObject(
          "guestUserVisitingLobywithPassword",
          add_password
        );

        // navigate("/lobby" + pathname);
        navigate("/auth" + pathname);
      } else {
        navigate("/auth" + pathname);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (rest.controlpanel) {
      console.log("controlpanel");
      setShowAppBar(false);
    }
    if (rest.checkValid && !localStorage.getObject("auth")) {
      console.log(location, slugs, "location.pathname", window.room);

      // validate api previously used
      // AxiosLocal.post("space/validate/", {
      //   slug: slugs.room,
      //   team_slug: slugs.team,
      // }).then((response) => {
      //   console.log(response.data, "response.dataurl");

      //   if (response.data.status == "Success") {
      //     // setValid(response.data.space_user_relation_exists);
      //     setValid(true);
      //   } else {
      //     setValid(false);
      //   }
      // });

      // validate api currently used
      const currentURL = window.location.href;
      const containsText = currentURL.includes("auth");
      let team_slug = getTeamSlugFromUrl("space");
      if (containsText) {
        team_slug = getTeamSlugFromUrl("lobby");
      }
      AxiosLocal.post("event/user/validation", {
        type: "normal",
        sub_space_slug: location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        ),
        team_slug: team_slug,
      }).then(function (response) {
        console.log(response.data, "response.dataurl");
        // let data = response.data.data
        // dispatch(setSpaces(data))
        //  let currentRoom = data.filter(space => {
        // return  `/${space.slug}` == location.pathname
        //  })[0]

        let currentSubRoom = "";

        if (response.data.valid || !response.data.is_private) {
          currentSubRoom = response.data.currentSubRoom;

          if (response.data.is_private) {
            if (response.data.is_invited) {
              // normal load
            } else {
              // redirect to space page
              navigate("/space/");
            }
          } else {
            if (response.data.is_invited) {
            } else {
              console.log("hiii", localStorage.getObject("auth"));
              if (!localStorage.getObject("auth")) {
                checkGuest(
                  response.data.currentSubRoom.is_lobby,
                  response.data.add_password,
                  location.pathname
                );
              }
            }
          }
        } else {
          navigate("/spaces");
        }
      });
    } else {
      setValid(true);
    }

    return () => {
      if (window.room) {
        console.log(location.pathname, "location.pathname", window.room);

        // DisconnectlivekitConnection(window.room);
      }
    };
  }, [
    checkGuest,
    location,
    navigate,
    rest.checkValid,
    rest.controlpanel,
    slugs,
  ]);
  return (
    <div
      style={{
        position: "relative",
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      {Valid && showAppBar && <ButtonAppBar />}

      {
        !loggedIn && Valid ? Component : <ErrorPage />

        // <Navigate to={{ pathname: "/404" }} />
      }
    </div>
  );
};

function App() {
  const theme = useSelector((state) => state.theme.themeData);

  // const permissions = useSelector((state) => state.permissions);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getObject("auth")
  );
  const [subscribedToCentrifugo, setsubscribedToCentrifugo] = useState(false);
  let timezone = (state) => state.userProfile.timezone;
  const dispatch = useDispatch();
  const setAuth = () => {
    if (window.localStorage.getObject("accessToken")) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    //alert(value);
  };

  // listen to location change

  useEffect(() => {
    setAuth();
    // localStorage.setObject("auth", JSON.stringify(isAuthenticated));
  }, [localStorage.getObject("auth")]);
  useEffect(() => {
    if (localStorage.getObject("auth") && !subscribedToCentrifugo) {
      dispatch(setCentrifugoClient(client));

      subscribeCentrifugoChannel(CHANNEL_TYPES.USER_LOGIN);
      if (localStorage.getObject("guestUser") == "false") {
        subscribeCentrifugoChannel(CHANNEL_TYPES.TEAM);
      }

      // permission channel
      AxiosLocal.post("centrifugo/permission").then((res) => {
        let PermissionToken = res.data.token;
        const permissionChanel = (window.permissionChanel =
          client.newSubscription(res.data.channel_name, {
            token: PermissionToken,
          }));

        permissionChanel.subscribe();
        dispatch(setPermissions({ ...res.data.permissions }));

        permissionChanel.on("publication", function (ctx) {
          console.log("permission", ctx.data);
          try {
            if (Object.keys(ctx.data).includes("mute_audio")) {
              window.room?.localParticipant.setMicrophoneEnabled(false);
              localStorage.setObject("audioMuted", true);
            }

            if (Object.keys(ctx.data).includes("mute_video")) {
              window.room.getLocalVideoTracks().forEach((value, key) => {
                console.log(value, key, "value, key");
                // value.track.source === "camera";
                try {
                  if (
                    JSON.parse(value.trackName).default &&
                    (value.track.source === "camera" ||
                      value.track.source === "unknown")
                  ) {
                    value.track.setTrackMuted(true);
                  }
                } catch (error) {
                  console.log(error);
                }
              });

              localStorage.setObject("videoMuted", true);
            }
          } catch (error) {
            console.log(error);
          }
          dispatch(setPermissions({ ...ctx.data }));
        });
      }).catch((err) => {
        console.log(err);
      });

      setsubscribedToCentrifugo(true);
    }

    return () => {
      if (isAuthenticated && subscribedToCentrifugo) {
        console.log("unsubscribing centrifugo");
        window.permissionChanel?.unsubscribe();
        window.themeChanel?.unsubscribe();
      }
    };
  }, [dispatch, isAuthenticated, subscribedToCentrifugo]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     AxiosLocal.get(`user/info/?user_id=${localStorage.getObject("id")}`).then(
  //       (res) => {
  //         let timezone = res.data.data[0]?.app_user.timezone
  //           ? res.data.data[0]?.app_user.timezone
  //           : "Asia/Kolkata";
  //         localStorage.setObject("user_timezone", timezone);
  //         dispatch(
  //           setuserProfile({
  //             timezone,
  //           })
  //         );
  //       }
  //     );
  //   }
  // }, []);

  function SEO() {
    return (
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={theme?.login?.favicon180x180}
        />

        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href={theme?.login?.favicon512x512}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={theme?.login?.favicon32x32}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={theme?.login?.favicon16x16}
        />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={theme?.login?.favicon16x16}
        />

        {/* <link rel="manifest" href="favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="favicon/safari-pinned-tab.svg"
          color="#0a4777"
        />
        <meta name="apple-mobile-web-app-title" content="Events.FOX" />
        <meta name="application-name" content="Events.FOX" />
        <meta name="msapplication-TileColor" content="#0a4777" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="apple-touch-icon"
          href="/favicon/android-chrome-192x192.png"
        />
        <link rel="icon" href="/favicon/favicon.ico" /> */}
      </Helmet>
    );
  }
  useEffect(() => {
    SEO();
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              isAuthenticated ? <Navigate to="/spaces" replace /> : <Login />
            }
          />

          <Route exact path="/register" element={<Register />} />
          <Route exact path="/invitation/:code" element={<Register />} />
          <Route exact path="/404" element={<ErrorPage />} />
          {/* <Route
            path="/controlpanel"
            element={
            
                <PrivateRoute component={<ControlPanel />} />
             
            }
          /> */}
          <Route
            path="/spaces"
            element={
              isAuthenticated ? (
                <PrivateRoute component={<EventSelection />} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/waiting/:room"
            element={
              isAuthenticated ? (
                <PrivateRoute component={<EventWaiting />} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          {/* track */}
          <Route path="/track/:team/:room/:trackId" element={<PublicTrack />} />

          <Route
            path="/track/:team/:room/:subroom/:trackId"
            element={<PublicTrack />}
          />
          <Route
            path="/lobby/:team/:room"
            element={
              isAuthenticated ? (
                <PrivateRoute component={<Lobby />} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/lobby/:team/:room/:subroom"
            element={
              isAuthenticated ? (
                <PrivateRoute component={<Lobby />} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/auth/:team/:room/:subroom" element={<EventGate />} />
          <Route path="/auth/:team/:room/" element={<EventGate />} />
          <Route
            path="/:team/:room/:subroom"
            element={
              <PrivateRoute checkValid={true} component={<MainEvent />} />
            }
          />
          <Route
            path="/:team/:room"
            element={
              <PrivateRoute checkValid={true} component={<MainEvent />} />
            }
          />
          {/* route for controlpannel */}

          <Route
            path="/:team/:room/controlpanel"
            element={
              isAuthenticated ? (
                <PrivateRoute
                  controlpanel={true}
                  component={<ControlPanel />}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/:team/:room/:subroom/controlpanel"
            element={
              isAuthenticated ? (
                <PrivateRoute
                  controlpanel={true}
                  component={<ControlPanel />}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          {/* <Route
            path="/admin/fileManager"
            element={
              isAuthenticated ? (
                <PrivateRoute
                  component={<MediaFileManager fileManagerType="all" />}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          /> */}

          {/*"Test"*/}
          <Route
            path="/test_keyboard_shortcuts"
            element={<KeyboardShortcuts />}
          />
          <Route
            path="/test_allow_camera_and_microphone"
            element={<AllowCameraAndMicrophone />}
          />
          <Route
            path="/test_camera_and_microphone_blocked"
            element={<CameraAndMicrophoneBlocked />}
          />
          <Route
            path="/test_camera_and_microphone_not_used"
            element={<CameraAndMicrophoneNotUsed />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword />}
          />

          {/* Permission group for reference  */}

          <Route path="/permission/create" element={<CreatePermissionPage />} />
          <Route path="/permission/list" element={<ListPermissionPage />} />
          <Route
            path="/permission/edit/:id"
            element={<UpdatePermissionPage />}
          />
          <Route
            path="/permission/listen/:id"
            element={<ListenPermissionPermissionPage />}
          />

          {/* Admin */}
          <Route path="/admin" element={isAuthenticated ? (
            <PrivateRoute
              controlpanel={true}
              component={<AdminPanel />}
            />
          ) : (
            <Navigate to="/" replace />
          )}>
            <Route path="/admin/spaces" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<EventPage />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
            <Route path="team" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<Team />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
            <Route path="analytics" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<Analytics />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
            {/* {permissions.view_workflows && */}
            <Route path="workflows" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<RolePage />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
            {/* } */}

            <Route path="account" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<AccountPage />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
            <Route path="fileManager" element={isAuthenticated ? (
              <PrivateRoute
                controlpanel={true}
                component={<File />}
              />
            ) : (
              <Navigate to="/" replace />
            )} />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
